<template>
  <v-row no-gutters>
    <v-col>
      <div v-if="status" id="tracking-list">
        <tracking-filter v-model="isFilterTrackingSidebarActive" :send-data="sendData" @filter-data="filterData" />

        <v-snackbars :objects.sync="snackbars" width="500" top right transition="slide-y-transition">
          <template v-slot:action="{ close }">
            <v-btn icon small @click="close()">
              <v-icon small>
                {{ icons.mdiClose }}
              </v-icon>
            </v-btn>
          </template>
        </v-snackbars>

        <v-dialog v-model="dialogStatus" max-width="500">
          <v-card>
            <v-card-title class="text-body-1">
              #{{ dialogStatusData.id }} No'lu Sipariş Durumunu Değiştiriyorsunuz
            </v-card-title>

            <v-card-text>
              <v-select
                v-model="dialogStatusValue"
                label="Durum"
                :items="$store.state.tanimlar.siparisDurum"
                class="rounded-b-0 rounded-t-lg"
                background-color="white"
                light
                :menu-props="{ bottom: true, offsetY: true }"
                dense
                filled
                hide-details
                return-object
              ></v-select>
              <v-divider />
              <v-textarea
                v-model="dialogStatusNote"
                filled
                class="rounded-t-0 rounded-b-lg"
                background-color="white"
                light
                dense
                label="Açıklama"
                placeholder="İsteğe Bağlı"
                persistent-placeholder
                hide-details
                auto-grow
                rows="2"
              ></v-textarea>
            </v-card-text>

            <v-card-actions>
              <v-spacer />
              <v-btn :disabled="loading" small text class="text-capitalize" @click="dialogStatusClose()">
                Vazgeç
              </v-btn>

              <v-btn
                :loading="loading"
                color="tertiary"
                depressed
                class="text-capitalize"
                @click="statusSave(dialogStatusData.id)"
              >
                Değiştir
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogLogs" max-width="900">
          <v-card>
            <v-card-title class="text-body-1">
              #{{ dialogLogsData.id }} No'lu Sipariş için Loglar
              <v-spacer />
              <v-btn icon @click="dialogLogsClose()">
                <v-icon>
                  {{ icons.mdiClose }}
                </v-icon>
              </v-btn>
            </v-card-title>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Satır No</th>
                    <th class="text-left">Üye Id</th>
                    <th class="text-left">Durum</th>
                    <th class="text-left">Açıklama</th>
                    <th class="text-left">Tarih</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in dialogLogsData.response" :key="item.id">
                    <td>{{ item.id }}</td>
                    <td>{{ item.uid }}</td>
                    <td>{{ item.stext }}</td>
                    <td>{{ item.ltext }}</td>
                    <td>{{ item.create_datetime }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-card-text> </v-card-text>
          </v-card>
        </v-dialog>

        <customer-list v-if="userData.role === 'PLSYR' && $vuetify.breakpoint.xsOnly" />

        <v-card
          tile
          :flat="$vuetify.breakpoint.mdAndDown"
          :class="[{ 'rounded-t-lg': !$vuetify.breakpoint.mdAndDown }, { 'rounded-l-xl': userData.role === 'SUPER' }]"
        >
          <v-toolbar :flat="!$vuetify.breakpoint.smAndDown" :height="$vuetify.breakpoint.smAndDown ? 57 : 87">
            <v-toolbar-title class="text-body-2 text-md-h6 font-weight-medium">
              <v-icon :large="!$vuetify.breakpoint.smAndDown" left>
                {{ icons.mdiCheckCircleOutline }}
              </v-icon>
              {{ $t('siparisOnay') }}
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <!-- 
            <v-btn plain text small :loading="loadingEXC" @click="dowloadEXC">
              <v-icon left>
                {{ icons.mdiDownload }}
              </v-icon>
              {{ $t('indir') }}
            </v-btn>
<v-btn plain text small @click.stop="isFilterTrackingSidebarActive = !isFilterTrackingSidebarActive">
                <v-badge v-if="isFilterNumber" overlap color="tertiary" dot offset-x="15" offset-y="5">
                  <v-icon left>
                    {{ icons.mdiFilter }}
                  </v-icon>
                </v-badge>
                <v-icon v-else left>
                  {{ icons.mdiFilter }}
                </v-icon>
                Filitre
              </v-btn> -->
          </v-toolbar>

          <!-- table -->
          <v-data-table
            v-model="selectedRows"
            :headers="tableColumns"
            :items="trackingListTable"
            :options.sync="options"
            :items-per-page="itemsPerPage"
            fixed-header
            :height="dataTableHeight"
            :loading="loading"
            item-key="id"
            show-expand
            single-expand
            :footer-props="{
              showFirstLastPage: true,
            }"
          >
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-data-table
                  :headers="childHeaders"
                  :items="item.products"
                  :items-per-page="-1"
                  hide-default-footer
                  dense
                >
                  <template #[`item.CUSTORDERNUM`]="{ item }">
                    <span> {{ item.CUSTORDERNUM }}</span>
                  </template>

                  <template v-slot:[`item.onayRet`]="{ item }">
                    <v-checkbox
                      style="padding-bottom: 15px"
                      color="success"
                      :disabled="item.silinecek == 1 ? true : false"
                      v-if="item.ERPSTATECODE == '6' || item.ERPSTATECODE == '7'"
                      v-model="item.onayRet"
                      hide-details
                    ></v-checkbox>
                  </template>

                  <template #[`item.silinecek`]="{ item }">
                    <v-switch
                      class="mb-4"
                      v-model="item.silinecek"
                      v-if="
                        item.ERPSTATECODE == '0' ||
                        item.ERPSTATECODE == '6' ||
                        item.ERPSTATECODE == '5' ||
                        item.ERPSTATECODE == '7'
                      "
                      hide-details
                      :disabled="item.onayRet == 1 ? true : false || item.B2BDELREQ == 1"
                      inset
                      color="success"
                    ></v-switch>
                  </template>

                  <template #[`item.DESDLVDATE`]="{ item }">
                    <div v-if="item.ERPSTATECODE == '7'">{{ $t('eskiTarih') }} : {{ item.DESDLVDATE }}</div>
                    <v-chip :color="item.DATECOLOR ? item.DATECOLOR : 'transparent'">
                      <v-col v-if="item.ERPSTATECODE != '0'">
                        <v-dialog ref="dialog" v-model="item.modal" persistent width="290px">
                          <template v-slot:activator="{ on, attrs }">
                            <div v-if="item.ERPSTATECODE == '7'">{{ $t('revizeTarihi') }}</div>
                            <v-text-field
                              class="black--text"
                              v-if="item.ERPSTATECODE == '7'"
                              v-model="item.revizeTarih"
                              :label="$t('tarihSeciniz')"
                              :append-outer-icon="icons.mdiCalendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              style="font-size: medium; padding: 0px; color: balck"
                            ></v-text-field>
                            <div v-else class="black--text">{{ item.DESDLVDATE }}</div>
                          </template>

                          <!-- :disabled="item.revizeTarih ? true : false"-->

                          <v-date-picker v-model="item.revizeTarih" scrollable color="secondary">
                            <v-btn text color="error" @click="item.modal = false"> Cancel </v-btn>
                            <v-btn
                              text
                              color="secondary"
                              @click="$refs.dialog.save(item.revizeTarih), (item.modal = false)"
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-dialog>
                      </v-col>

                      <span v-else class="black--text">{{ item.DESDLVDATE }} </span>
                    </v-chip>
                  </template>

                  <template #[`item.PRODDATE`]="{ item }">
                    <span class="ml-4" v-if="item.ERPSTATECODE != 0">
                      {{ item.PRODDATE }}
                    </span>
                  </template>
                  <template #[`item.ERPSTATETXT`]="{ item }">
                    <v-chip v-if="item.ERPSTATECODE == '0'" color="error" label small>
                      {{ item.ERPSTATETXT }}
                    </v-chip>
                    <v-chip v-if="item.ERPSTATECODE == '1'" color="success" label small>
                      {{ item.ERPSTATETXT }}
                    </v-chip>
                    <v-chip v-if="item.ERPSTATECODE == '2'" color="info" label small>
                      {{ item.ERPSTATETXT }}
                    </v-chip>
                    <v-chip v-if="item.ERPSTATECODE == '3'" color="tertiary" label small>
                      {{ item.ERPSTATETXT }}
                    </v-chip>
                    <v-chip v-if="item.ERPSTATECODE == '4'" color="primary" label small>
                      {{ item.ERPSTATETXT }}
                    </v-chip>
                    <v-chip v-if="item.ERPSTATECODE == '5'" color="success" label small>
                      {{ item.ERPSTATETXT }}
                    </v-chip>
                    <v-chip v-if="item.ERPSTATECODE == '6'" color="success" label small>
                      {{ item.ERPSTATETXT }}
                    </v-chip>
                    <v-chip v-if="item.ERPSTATECODE == '7'" color="success" label small>
                      {{ item.ERPSTATETXT }}
                    </v-chip>
                  </template>
                  <template #[`item.GRANDTOTALITEM`]="{ item }">
                    <span> {{ item.GRANDTOTALITEM | currency }}</span>
                  </template>
                  <template #[`item.SPRICE`]="{ item }">
                    <v-chip :color="item.PRICECOLOR">
                      <span> {{ item.SPRICE | currency }}</span>
                    </v-chip>
                  </template>
                  <template #[`item.sub_total`]="{ item }">
                    <span class="text-caption"> {{ item.sub_total | currency }}</span>
                  </template>
                  <template #[`item.total_price`]="{ item }">
                    <span class="text-caption"> {{ item.total_price | currency }}</span>
                  </template>
                </v-data-table>
                <v-btn
                  v-if="item.showFinalBtn == false"
                  class="mt-6 ml-2"
                  color="success"
                  @click=";(isFinal = 0), saveTable(item)"
                  >{{ $t('degisiklikleriKaydet') }}
                </v-btn>
                <v-btn
                  v-if="item.showFinalBtn == true"
                  class="mt-6 ml-2 white--text"
                  color="tertiary"
                  @click=";(isFinal = 1), saveTable(item)"
                  >{{ $t('siparisBelgesiniOnayla') }}
                </v-btn>
              </td>
            </template>

            <!-- Text -->
            <template #[`item.id`]="{ item }">
              <div v-if="$vuetify.breakpoint.xsOnly" class="text-body-1 my-2">
                {{ item.id }} {{ $t('numaraliSiparis') }}
              </div>
              <span v-else class="font-weight-medium">{{ item.id }} {{ $t('numaraliSiparis') }}</span>
            </template>
          </v-data-table>
        </v-card>
      </div>
      <div v-else>
        <customer-list v-if="userData.role === 'PLSYR' && $vuetify.breakpoint.xsOnly" />
        <v-alert text outlined class="mx-3 mx-lg-0 rounded-pill mt-2 mt-md-0 text-caption" type="warning">
          Lütfen yukarıdan bir müşteri seçin
        </v-alert>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import {
  mdiArrowCollapseLeft,
  mdiArrowCollapseRight,
  mdiChevronLeft,
  mdiChevronRight,
  mdiClipboardList,
  mdiClose,
  mdiDelete,
  mdiDownload,
  mdiFilter,
  mdiFilterOff,
  mdiOrderNumericAscending,
  mdiPlusThick,
  mdiRefresh,
  mdiSend,
  mdiCalendar,
  mdiCheckCircleOutline,
} from '@mdi/js'

import CustomerList from '@/components/CustomerList.vue'
import store from '@/store'
import { postData } from '@utils'
import { onMounted, onUnmounted, ref, watch } from '@vue/composition-api'
import VSnackbars from 'v-snackbars'
import Vue from 'vue'
import trackingStoreModule from '../trackingStoreModule'
import trackingFilter from './TrackingFilter.vue'
import i18n from '@/plugins/i18n'

const userData = JSON.parse(localStorage.getItem('userData'))

export default {
  components: {
    trackingFilter,
    VSnackbars,
    CustomerList,
  },
  setup() {
    const date = new Date().toISOString().substr(0, 10)
    const menu = ref(false)
    const menu2 = ref(false)
    const dateSelected = ref(false)
    const menuref = ref(null)

    const PRODUCT_APP_STORE_MODULE_NAME = 'app-tracking'
    const snackbars = ref([])
    const userData = JSON.parse(localStorage.getItem('userData'))
    const status = ref(false)

    const sendData = ref({})
    onMounted(() => {
      if (userData.role === 'PLSYR' && store.state.plasiyerCustomer === null) {
        status.value = false
      } else {
        status.value = true
        loading.value = true
        fetchTrackingsOnay()
      }
    })

    watch(
      () => store.state.plasiyerCustomer,
      () => {
        if (store.state.plasiyerCustomer) {
          status.value = true
          loading.value = true
          // fetchTrackingsOnay()
        }
      },
    )

    // this won't work because we are passing a number to watch()

    // Register module
    if (!store.hasModule(PRODUCT_APP_STORE_MODULE_NAME)) {
      store.registerModule(PRODUCT_APP_STORE_MODULE_NAME, trackingStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PRODUCT_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(PRODUCT_APP_STORE_MODULE_NAME)
      }
    })

    const isFilterNumber = ref(0)
    const isFilterTrackingSidebarActive = ref(false)
    const trackingListTable = ref([])
    const showButton = ref(false)

    const siparisNo = ref('')
    const childHeaders = [
      {
        text: i18n.t('satirNo'),
        value: 'ITEMNUM',
        sortable: false,
        width: '120px',
        align: 'center',
      },
      {
        text: i18n.t('musteriMalzemeKodu'),
        value: 'HTKCUSMAT',
        sortable: false,
        width: '150px',
        filter: f => `${f}`.toLowerCase().includes(siparisNo.value.toLowerCase()),
      },
      {
        text: i18n.t('poNumarasi'),
        value: 'CUSTORDERNUM',
        sortable: false,
        width: '150px',
      },

      {
        text: i18n.t('malzemeKodu'),
        value: 'MATERIAL',
        sortable: false,
        width: '150px',
      },

      {
        text: i18n.t('malzemeAciklamasi'),
        value: 'MTEXT',
        width: '300px',
        sortable: false,
      },
      {
        text: i18n.t('miktar'),
        value: 'AVAILQTY',
        width: '100px',
        sortable: false,
      },
      {
        text: i18n.t('birimFiyat'),
        value: 'SPRICE',
        sortable: false,
        width: '120px',
        align: 'center',
      },
      {
        text: i18n.t('satirToplami'),
        value: 'GRANDTOTALITEM',
        sortable: false,
        width: '150px',
      },
      {
        text: i18n.t('sevkTarihi'),
        value: 'DESDLVDATE',

        width: '200px',
        sortable: false,
      },
      {
        text: i18n.t('planlananSevkTarihi'),
        value: 'PRODDATE',
        width: '200px',
        sortable: false,
      },
      {
        text: i18n.t('statü'),
        value: 'ERPSTATETXT',
        width: '200px',
        sortable: false,
      },
      {
        text: i18n.t('onay'),
        value: 'onayRet',
        width: '100px',
        sortable: false,
      },
      {
        text: i18n.t('silmeTalebi'),
        value: 'silinecek',
        width: '100px',
        sortable: false,
      },
    ]
    const tableColumns = [
      {
        text: i18n.t('siparisNo'),
        value: 'id',
        sortable: false,
        width: '100%',
      },
    ]
    /*
    const childHeaders = [
      {
        text: 'Stok Kodu',
        value: 'stok_kodu',
        sortable: false,
        width: '150px',
      },
      {
        text: 'Kategori',
        value: 'kategori_path',
        sortable: false,
        width: '250px',
      },
      {
        text: 'Stok Adı',
        value: 'baslik',
      },
      {
        text: 'Miktar',
        value: 'qty',
        width: '100px',
        sortable: false,
      },
      {
        text: 'Birim',
        value: 'birim',
        sortable: false,
        width: '120px',
      },
      {
        text: 'Fiyat',
        value: 'unit_price',
        sortable: false,
        width: '150px',
      },
      {
        text: 'Ara Toplam',
        value: 'sub_total',
        sortable: false,
        width: '150px',
      },
      {
        text: 'Toplam',
        value: 'total_price',
        sortable: false,
        width: '150px',
      },
    ]
*/
    const searchQuery = ref('')
    const urunKodFilter = ref(null)
    const idFilter = ref(null)
    const siparisDurumFilter = ref(null)
    const odemeDurumFilter = ref(null)
    const startDateFilter = ref(null)
    const endDateFilter = ref(null)
    const minPriceFilter = ref(null)
    const maxPriceFilter = ref(null)
    const modal = ref(false)

    const statusFilter = ref(null)
    const baslikFilter = ref(null)
    const customerFilter = ref(null)
    const totalTrackingListTable = ref(0)
    const customerGroupOptions = ref([])
    const pricelistOptions = ref([])
    const loading = ref(false)
    const dialogLogs = ref(false)
    const dialogStatus = ref(false)
    const disabledItems = ref(false)
    const onayRetSwitch = ref(0)

    const dialogStatusData = ref({})
    const dialogStatusValue = ref(null)
    const dialogStatusNote = ref(null)
    const loadingItems = ref([])
    const options = ref({
      sortBy: ['id'],
      sortDesc: [true],
    })
    const options1 = ref({
      sortBy: ['ITEMNUM'],
      sortDesc: [false],
    })
    const trackingTotalLocal = ref([])
    const selectedRows = ref([])
    const dialogLogsData = ref({
      id: null,
      response: null,
    })

    const filterData = item => {
      idFilter.value = item.id || null
      siparisDurumFilter.value = item.siparisDurum || null
      odemeDurumFilter.value = item.odemeDurum || null
      startDateFilter.value = item.startDate || null
      endDateFilter.value = item.endDate || null
      minPriceFilter.value = item.minPrice || null
      maxPriceFilter.value = item.maxPrice || null

      let i = 0
      // eslint-disable-next-line no-restricted-syntax, no-unused-vars
      for (const [key, value] of Object.entries(item)) {
        if (value) {
          i += 1
        }
      }
      isFilterNumber.value = i

      sendData.value = { ...item }
    }
    const json_data = ref([])
    const productStates = ref([])

    const mattypeFilter = ref('')
    const erpordernoFilter = ref('')
    const carikodFilter = ref('')
    const customernameFilter = ref('')

    const fetchTrackingsOnay = (status = false, id = null) => {
      store
        .dispatch('app-tracking/fetchTrackingsOnay', {})
        .then(response => {
          if (response.error == 0) {
            /*
            response.detail.forEach(item => {
              const dizi = item.products
              dizi.forEach(item => {
                const parts = item.DESDLVDATE.split('/')
                const day = parts[0].padStart(2, '0')
                const month = parts[1].padStart(2, '0')
                const year = parts[2]

                item.DESDLVDATE = `${year}-${month}-${day}`
              })
            })
            */

            trackingListTable.value = response.detail

            trackingListTable.value.forEach(item => {
              item.products.forEach(item => {
                console.log(item.silinecek)
                if (item.silinecek == 2) {
                  item.silinecek = 0
                }
              })
            })

            /*

            response.detail.map(element => {
              element.products.map(item => {
                console.log(item.STATECODE)
                if (item.STATECODE == '2' || item.STATECODE == '4') {
                  showButton.value = true
                  console.log(showButton.value)
                }
              })
            })*/

            //console.log(response.detail[0][0].KEY)
            //totalTrackingListTable.value = Number(response.response.pagination.totalRec)

            //json_data.value = response.response.result

            if (status) {
              dialogStatus.value = false
              snackbars.value.push({
                message: `#${id} No'lu siparişin durumu ${dialogStatusValue.value.text} olarak güncellendi.`,
                color: 'info',
                timeout: 5000,
              })
            }
            loading.value = false
          } else {
            loading.value = false
            Vue.swal({
              title: 'Hata',
              text: 'Bir Hata Oluştu',
              width: '350px',
              icon: 'error',
              background: '#FF4C51',
              showCloseButton: false,
              showConfirmButton: true,
              confirmButtonColor: '#cc3d41',
            })
          }
        })
        .catch(e => {
          loading.value = false
          Vue.swal({
            title: 'Hata',
            text: e.message === "Cannot read properties of null (reading 'length')" ? 'Sistem Hatası' : e.message,
            width: '350px',
            icon: 'error',
            background: '#FF4C51',
            showCloseButton: false,
            showConfirmButton: true,
            confirmButtonColor: '#cc3d41',
          })
        })
    }

    /*
    const trackingStatusUpdate = id => {
      store
        .dispatch('app-tracking/fetchTrackingsOnaytatusUpdate', {
          method: 'updateOrderStatus',
          id,
          status: dialogStatusValue.value.value,
          note: dialogStatusNote.value,
        })
        .then(() => {
          fetchTrackingsOnay(true, id)
        })
        .catch(error => {
          console.log(error)
        })
    }
    */

    const trackingLogs = id => {
      store
        .dispatch('app-tracking/fetchTrackingLogs', {
          method: 'getOrderLogs',
          id,
        })
        .then(response => {
          disabledItems.value = false
          loadingItems.value = []
          dialogLogs.value = true
          dialogLogsData.value.id = id
          dialogLogsData.value.response = response.detail
        })
        .catch(error => {
          console.log(error)
        })
    }

    const dialogLogsClose = () => {
      dialogLogs.value = false
    }

    const statusChange = (id, durum) => {
      dialogStatusData.value = { id, durum }
      dialogStatusValue.value = durum
      dialogStatus.value = true
    }

    const dialogStatusClose = () => {
      dialogStatusNote.value = null
      dialogStatus.value = false
    }

    const statusSave = id => {
      loading.value = true
      trackingStatusUpdate(id)
    }

    watch(
      [
        searchQuery,
        idFilter,
        mattypeFilter,
        erpordernoFilter,
        customernameFilter,
        carikodFilter,
        siparisDurumFilter,
        odemeDurumFilter,
        startDateFilter,
        endDateFilter,
        minPriceFilter,
        maxPriceFilter,
        options,
      ],
      () => {
        loading.value = true
        selectedRows.value = []
        // fetchTrackingsOnay()
      },
    )

    watch(dialogStatus, val => {
      if (!val) {
        dialogStatusClose()
      }
    })

    watch(dialogLogs, val => {
      if (!val) {
        dialogLogsClose()
      }
    })

    const statusFind = (arr, value, select) => store.state?.tanimlar?.[arr].find(item => item.value === value)?.[select]

    const logList = id => {
      loadingItems.value.push(id)
      disabledItems.value = true
      trackingLogs(id)
    }
    const isFinal = ref(0)

    const saveTable = item => {
      Vue.swal({
        title: i18n.t('eminMisin'),
        text: isFinal.value == 0 ? i18n.t('secimleriOnayliyormusun') : i18n.t('belgeKaydet'),
        icon: 'question',
        showCancelButton: true,
        // background: isFinal == 0 ? '' : '#FFB400',
        confirmButtonText: i18n.t('onayliyorum'),
      }).then(result => {
        if (result.isConfirmed) {
          console.log(item)
          const dizi1 = {
            id: item.id,
            products: [],
          }

          item.products.forEach(product => {
            dizi1.products.push({
              ...product,
            })
          })

          const data = JSON.stringify(dizi1)
          console.log(data)

          const param = {
            method: 'saveOrderConfirm',
            data,
            isFinal: isFinal.value,
          }

          store
            .dispatch('postMethod', param)
            .then(response => {
              if (response.error === 0) {
                Vue.swal({
                  title: i18n.t('secimlerinizKaydedildi'),
                  text: i18n.t('islemBasarili'),
                  icon: 'success',
                  timer: 1700,
                  background: '#56CA00',
                  timerProgressBar: true,
                  showConfirmButton: false,
                })
                trackingListTable.value = []
                loading.value = true

                console.log('basarılı')
                fetchTrackingsOnay()
              } else {
                //dialog.value = false
                Vue.swal({
                  title: 'Hata',
                  html: response.msg,
                  icon: 'error',
                  background: '#FF4C51',
                  timerProgressBar: true,
                  showConfirmButton: false,
                })
              }
            })
            .catch(error => {
              dialog.value = false
              console.log(error)
            })
        }
      })
    }

    const loadingItemsStatus = id => loadingItems.value.includes(id)

    const loadingEXC = ref(false)
    const dowloadEXC = async () => {
      loadingEXC.value = true
      postData({
        method: 'getOrderAndLinesToExcel',
      }).then(response => {
        if (response.error === 0) {
          window.open(response.file, '_blank')
        }
        loadingEXC.value = false
      })
    }

    return {
      showButton,
      onayRetSwitch,
      siparisNo,
      dowloadEXC,
      loadingEXC,
      productStates,
      childHeaders,
      userData,
      modal,
      sendData,
      json_data,
      dialogLogsClose,
      dialogLogsData,
      loadingItemsStatus,
      loadingItems,
      logList,
      disabledItems,
      dialogLogs,
      dialogStatusData,
      dialogStatusValue,
      dialogStatusNote,
      statusChange,
      statusSave,
      dialogStatusClose,
      dialogStatus,
      statusFind,
      isFilterNumber,
      trackingListTable,
      tableColumns,
      searchQuery,
      urunKodFilter,
      baslikFilter,
      idFilter,
      erpordernoFilter,
      dateSelected,
      saveTable,
      carikodFilter,
      date,
      menu,
      modal,
      menu2,
      menuref,
      mattypeFilter,
      customernameFilter,
      statusFilter,
      totalTrackingListTable,
      loading,
      options,
      filterData,
      options1,
      trackingTotalLocal,
      isFilterTrackingSidebarActive,
      selectedRows,
      fetchTrackingsOnay,
      snackbars,
      customerFilter,
      customerGroupOptions,
      pricelistOptions,
      isFinal,
      userData,
      status,

      // icons
      icons: {
        mdiCalendar,
        mdiDelete,
        mdiClose,
        mdiClipboardList,
        mdiOrderNumericAscending,
        mdiFilter,
        mdiDownload,
        mdiSend,
        mdiFilterOff,
        mdiRefresh,
        mdiPlusThick,
        mdiArrowCollapseLeft,
        mdiArrowCollapseRight,
        mdiChevronLeft,
        mdiChevronRight,
        mdiCheckCircleOutline,
      },
    }
  },
  data() {
    return {
      itemsPerPage: null,
      panel: null,
      date: null,
      filterStatus: false,
      dataTableHeight: '',
      height: {
        system: 0,
        top: this.$vuetify.breakpoint.smAndDown ? 140 : 87,
        footer: this.$vuetify.breakpoint.smAndDown ? 47 : 47,
      },
      json_fields: {
        'Satış No': 'id',
        'Sipariş Tarihi': {
          field: 'siparis_tarihi',
          callback: value => `${value.slice(0, 16)}`,
        },
        Durum: 'durum_txt',
        Fiyat: {
          field: 'total_price',
          callback: value => `${value.slice(0, -1)}`,
        },

        'Para Birimi': 'currency',
        'Ödeme Tipi': {
          field: 'odeme_tipi',
          callback: value => (value == 2 ? 'Havale' : 'Kredi Kartı'),
        },
      },

      json_meta: [
        [
          {
            key: 'charset',
            value: 'utf-8',
          },
        ],
      ],
    }
  },

  watch: {
    panel(val) {
      if (val === 0) {
        setTimeout(() => {
          this.height.top = 250
          this.onResize()
        }, 300)
      } else {
        this.height.top = 48
        this.onResize()
      }
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.onResize()
      window.addEventListener('resize', this.onResize)
    })
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize() {
      this.itemsPerPage = Math.ceil(window.innerHeight / 100) + 4
      if (userData.role === 'SUPER') {
        this.dataTableHeight = window.innerHeight - 135
      } else {
        this.dataTableHeight = window.innerHeight - 90 - this.height.top - this.height.footer - 1
      }

      const r = document.querySelector(':root')
      r.style.setProperty('--height', `${(this.dataTableHeight - 55) / this.itemsPerPage}px`)
    },
  },
}
</script>

<style lang="scss">
:root {
  --height: 30px;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  //height: var(--height);
}
.v-data-footer__select {
  display: none !important;
}

.v-data-table__expanded__content > td {
  //background: #28243d !important;
  padding: 0 0 70px 0 !important;
}
.v-application.theme--dark .v-data-table .v-data-table__expanded__content th {
  background-color: #0d6efd !important;
}
.theme--light.v-data-table td {
  color: inherit !important;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding: 0px;
}
.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  padding: 0px;
}
</style>
